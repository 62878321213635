import { keyBy } from "lodash";

import { UiBackendv1CompanyTreeItem } from "lib/api/common/data-contracts";
import { CompanyHierarchy } from "lib/constants/company-hierarchy";

const getChildren = (id: string, companies: UiBackendv1CompanyTreeItem[]) => {
    return companies
        .filter((company) => company.parent_id === id)
        .map((company) => company.b2b_company_id || "");
};

export const generateTreeData = ({
    companies,
}: {
    companies?: UiBackendv1CompanyTreeItem[];
}) => {
    const rootCompanyId = companies?.find(
        (company) => company.type === CompanyHierarchy.ROOT,
    )?.b2b_company_id;

    const localCompaniesData = (companies || []).map(
        (company, _, allCompanies) => {
            if (
                !allCompanies.find(
                    (item) => item.b2b_company_id === company.parent_id,
                ) &&
                company.type !== CompanyHierarchy.ROOT
            ) {
                return { ...company, parent_id: rootCompanyId };
            }
            return company;
        },
    );

    return keyBy(
        (localCompaniesData || []).map((company, _, allCompanies) => ({
            children: getChildren(company.b2b_company_id || "", allCompanies),
            nodeProps: {
                title: company.b2b_company_title,
                companyStatusData: {
                    status:
                        company.type === CompanyHierarchy.ROOT
                            ? company.is_active
                            : company.is_active && company.is_parent_active,
                    message: company.is_active
                        ? ["label.active", "Active"]
                        : ["label.inactive", "Inactive"],
                },
                userAccess: company.access?.user_access,
            },
            id: company.b2b_company_id || "",
            isDraggable: false,
            isFolder: company.type !== CompanyHierarchy.PROJECT,
            isRoot: company.type === CompanyHierarchy.ROOT,
            isSelected: false,
            parentId: company.parent_id || "",
        })),
        "id",
    );
};
