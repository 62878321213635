import { cloneDeep } from "lodash";

import { UserAccess } from "lib/constants/user-access";

import { changeAccessForAllChildren } from "../change-access-for-all-children";
import { getPathToFirstDirectAccess } from "../get-path-to-first-direct-access";
import { ItemsById, TreeData } from "../../types";

type BuildNextAccessesParams = {
    itemsById: ItemsById;
    treeData: TreeData;
    selectedCompanyId: string;
};

export const buildNextAccesses = ({
    itemsById,
    selectedCompanyId,
    treeData,
}: BuildNextAccessesParams) => {
    let cloneItemsById = cloneDeep(itemsById);
    const selectedItem = cloneItemsById[selectedCompanyId];

    if (selectedItem) {
        if (selectedItem.access.user_access === UserAccess.NULL) {
            selectedItem.access.user_access = UserAccess.DIRECT;
            cloneItemsById = changeAccessForAllChildren({
                id: selectedItem.b2b_company_id,
                nextAccess: UserAccess.INHERITED,
                items: cloneItemsById,
                treeData,
            });
        } else {
            if (selectedItem.access.user_access === UserAccess.DIRECT) {
                selectedItem.access.user_access = UserAccess.NULL;
                cloneItemsById = changeAccessForAllChildren({
                    id: selectedItem.b2b_company_id,
                    nextAccess: UserAccess.NULL,
                    items: cloneItemsById,
                    treeData,
                });
            }

            if (selectedItem.access.user_access === UserAccess.INHERITED) {
                selectedItem.access.user_access = UserAccess.NULL;

                cloneItemsById = changeAccessForAllChildren({
                    id: selectedItem.b2b_company_id,
                    nextAccess: UserAccess.NULL,
                    items: cloneItemsById,
                    treeData,
                });

                const path = getPathToFirstDirectAccess({
                    id: selectedItem.b2b_company_id,
                    itemsById: cloneItemsById,
                });

                path.forEach((id) => {
                    const node = cloneItemsById[id];
                    if (node) {
                        node.access.user_access = UserAccess.NULL;

                        treeData[id].children.forEach((childId) => {
                            const childNode = cloneItemsById[childId];

                            if (childNode) {
                                childNode.access.user_access =
                                    childNode.access.user_access ===
                                    UserAccess.INHERITED
                                        ? UserAccess.DIRECT
                                        : UserAccess.NULL;
                            }
                        });
                    }
                });
            }
        }
    }

    return cloneItemsById;
};
