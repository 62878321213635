import { UserAccess } from "lib/constants/user-access";
import { ItemsById } from "../../types";

type GetPathToFirstDirectAccessParams = {
    id: string;
    itemsById: ItemsById;
};

export const getPathToFirstDirectAccess = ({
    id,
    itemsById,
}: GetPathToFirstDirectAccessParams) => {
    const path: string[] = [];
    let currentId = itemsById[id].parent_id;

    while (itemsById[currentId].access.user_access !== UserAccess.DIRECT) {
        path.push(currentId);
        currentId = itemsById[currentId].parent_id;
    }

    path.push(currentId);

    return path;
};
