type TraverseDepthFirstParams = {
    treeData: Record<string, any>;
    callback: (nodeId: string) => void;
    rootId: string;
};

export const traverseDepthFirst = ({
    treeData,
    callback,
    rootId,
}: TraverseDepthFirstParams) => {
    const stack = [rootId];

    while (stack.length > 0) {
        const nodeId = stack.pop();
        if (nodeId) {
            const node = treeData[nodeId];

            callback(nodeId);

            // Добавляем детей в стек в обратном порядке, чтобы первый ребенок был обработан первым.
            if (node.children) {
                for (let i = node.children.length - 1; i >= 0; i--) {
                    stack.push(node.children[i]);
                }
            }
        }
    }
};
