import React from "react";
import { useTranslation } from "next-i18next";

import { NodeRenderProps } from "../../node-render";
import {
    NodeContainer,
    NodeControlLeftBlock,
} from "components/molecules/tree/node-render/components/node-render.styled";
import { NodeTitle } from "components/molecules/tree/node-render/components/node-title/node-title";
import { Label } from "components/atoms/label/label.component";
import { ACTIVE, INACTIVE } from "lib/constants/status";
import { Checkbox } from "components/atoms/checkbox/checkbox.component";
import { UserAccess } from "lib/constants/user-access";
import {
    StyledNodeControlContent,
    StyledNodeControlRightBlock,
    StyledStatusBlock,
} from "../../node-render.styled";
import { testIds } from "./project.interface";

export const Project = ({ nodeProps, onChange, id }: NodeRenderProps) => {
    const { t } = useTranslation("users");

    return (
        <NodeContainer>
            <StyledNodeControlContent>
                <NodeControlLeftBlock>
                    <NodeTitle title={nodeProps.title} />
                </NodeControlLeftBlock>
                <StyledNodeControlRightBlock>
                    <StyledStatusBlock>
                        <Label
                            status={
                                nodeProps.companyStatusData.status
                                    ? ACTIVE
                                    : INACTIVE
                            }
                        >
                            {t(
                                nodeProps.companyStatusData.message[0],
                                nodeProps.companyStatusData.message[1],
                            )}
                        </Label>
                    </StyledStatusBlock>

                    <Checkbox
                        onChange={() => onChange(id)}
                        checked={
                            nodeProps.userAccess === UserAccess.DIRECT ||
                            nodeProps.userAccess === UserAccess.INHERITED
                        }
                        data-testid={`${testIds.projectCard}-${id}`}
                    />
                </StyledNodeControlRightBlock>
            </StyledNodeControlContent>
        </NodeContainer>
    );
};
