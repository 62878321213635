import styled from "styled-components";

import { colors } from "lib/palette";
import { Text } from "components/atoms/text/text.component";

export const StyledCompanyInfoBlock = styled.div`
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    gap: 15px;
`;

export const StyledCompanyItem = styled.div`
    width: 100%;
    overflow: hidden;
    padding: 10px 15px;
    border: 1px solid ${colors.blue_3};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 5px;
    gap: 10px;
`;

export const StyledStepContent = styled.div`
    width: 100%;
`;

export const StyledIconBlock = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
`;

export const StyledItemTitle = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
`;

export const StyledText = styled(Text)`
    text-align: center;
    margin-top: 15px;
`;
