import { breakpoints } from "lib/breakpoints";
import styled from "styled-components";

export const StyledNodeControlContent = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    gap: 12px;
    padding: 11px 15px;
    overflow: hidden;
`;

export const StyledNodeControlRightBlock = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    gap: 32px;
`;

export const StyledStatusBlock = styled.div`
    @media (max-width: ${breakpoints.sm}px) {
        display: none;
    }
`;

export const StyledCheckboxBlock = styled.div`
    width: 20px;
`;
