import { Unit } from "./components/unit/unit";
import { Project } from "./components/project/project";
import { FolderProps } from "components/molecules/tree/node-render/node-render.interface";
import { Item } from "components/molecules/tree/tree.interface";
import { UserAccess } from "lib/constants/user-access";

export type CompanyNode = {
    title: string;
    companyStatusData: {
        status: boolean;
        message: [string, string];
    };
    userAccess: UserAccess;
};

export type NodeRenderProps = Omit<Item<CompanyNode>, "children"> &
    FolderProps & { onChange: (id: string) => void; hasAccessToRoot: boolean };

export const NodeRender = (props: NodeRenderProps) => {
    if (props.isFolder) {
        return <Unit {...props} />;
    }

    return <Project {...props} />;
};

NodeRender.displayName = "NodeRender";
