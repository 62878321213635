import React, { useCallback, useMemo } from "react";
import { keyBy } from "lodash";

import { CompanyHierarchy } from "lib/constants/company-hierarchy";
import { generateTreeData } from "lib/utils/entities/companies/company-checkbox-tree-data.utilit";
import { Tree } from "components/molecules/tree/tree";
import { UiBackendv1CompanyTreeItem } from "lib/api/common/data-contracts";

import { CompaniesCheckboxTreeProps } from "./companies-checkbox-tree.interface";
import {
    NodeRender,
    NodeRenderProps,
} from "./components/node-render/node-render";
import { buildNextAccesses } from "./utils";

export const CompaniesCheckboxTree = ({
    items,
    availableItems = [],
    updateItems,
}: CompaniesCheckboxTreeProps) => {
    const treeData = useMemo(
        () => generateTreeData({ companies: items }),
        [items],
    );

    const itemsById = useMemo(
        () => keyBy(items, "b2b_company_id"),
        [items],
    ) as Record<string, Required<UiBackendv1CompanyTreeItem>>;

    const rootCompanyId =
        items.find((item) => item.type === CompanyHierarchy.ROOT)
            ?.b2b_company_id || "";

    const hasAccessToRoot = availableItems.includes(rootCompanyId);

    const handleOnChange = useCallback(
        (id: string) => {
            const nextAccesses = buildNextAccesses({
                selectedCompanyId: id,
                itemsById,
                treeData,
            });

            updateItems(Object.values(nextAccesses));
        },
        [itemsById, updateItems, treeData],
    );

    if (!Object.keys(treeData).length) {
        return null;
    }

    return (
        <Tree
            nodes={treeData}
            rootId={rootCompanyId}
            canDragAndDrop={false}
            nodeRender={(data: NodeRenderProps) => (
                <NodeRender
                    {...data}
                    onChange={handleOnChange}
                    hasAccessToRoot={hasAccessToRoot}
                />
            )}
        />
    );
};
