import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { AggregateService } from "lib/api/api";
import { V1GetCompanyTreeResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";
export const useGetUserAccessQueryKey = "get-user-access";

type Options = Omit<
    UseQueryOptions<
        V1GetCompanyTreeResponse,
        HttpErrorType,
        V1GetCompanyTreeResponse,
        [typeof useGetUserAccessQueryKey, string]
    >,
    "queryKey" | "queryFn"
>;

export function useGetUserAccessQuery(userId: string, options?: Options) {
    return useQuery(
        [useGetUserAccessQueryKey, userId],
        async () => {
            return (
                await AggregateService.uiBackendServiceGetCompanyTree(userId)
            ).data;
        },
        options,
    );
}
