import { AuthService } from "lib/api/api";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { V1UnblockResponse } from "../common/data-contracts";
import { HttpErrorType } from "../types";

type Options = UseMutationOptions<V1UnblockResponse, HttpErrorType, string>;

export function useUnblockUserMutation(options?: Options) {
    return useMutation(async (args) => {
        return (await AuthService.authServiceUnblock(args, {})).data;
    }, options);
}
