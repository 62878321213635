import { AggregateService } from "lib/api/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
    RpcStatus,
    UiBackendv1GetUserResponse,
} from "../common/data-contracts";

export const useGetUserByIdQueryKey = "get-user-by-id";

type Options = UseQueryOptions<
    UiBackendv1GetUserResponse,
    RpcStatus,
    UiBackendv1GetUserResponse,
    [typeof useGetUserByIdQueryKey, string]
>;

type UsersServiceGetUserByIdParams = Parameters<
    (typeof AggregateService)["uiBackendServiceGetUser"]
>["0"];

export function useGetUserByIdQuery(
    userId: UsersServiceGetUserByIdParams,
    options?: Options,
) {
    return useQuery(
        [useGetUserByIdQueryKey, userId],
        async () => {
            return (await AggregateService.uiBackendServiceGetUser(userId))
                .data;
        },
        options,
    );
}
