import { useTranslation } from "next-i18next";

import { Checkbox } from "components/atoms/checkbox/checkbox.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { UiBackendv1CompanyTreeItem } from "lib/api/common/data-contracts";
import { UserAccess } from "lib/constants/user-access";
import {
    CompaniesCheckboxListProps,
    testIds,
} from "./companies-checkbox-list.interface";
import {
    StyledCompanyInfoBlock,
    StyledCompanyItem,
    StyledIconBlock,
    StyledItemTitle,
    StyledText,
} from "./companies-checkbox-list.styled";

export const CompaniesCheckboxList = ({
    items,
    updateItems,
}: CompaniesCheckboxListProps) => {
    const { t } = useTranslation("users");

    const handleCheckCompany = (status: boolean, id?: string) => {
        const newState = items.map((i) => {
            if (i?.b2b_company_id === id) {
                return {
                    ...i,
                    access: {
                        ...i.access,
                        user_access: status
                            ? UserAccess.DIRECT
                            : UserAccess.NULL,
                    },
                };
            }
            return i;
        });
        updateItems(newState);
    };

    return (
        <>
            {items.length ? (
                items.map((item: UiBackendv1CompanyTreeItem) => (
                    <StyledCompanyItem key={item?.b2b_company_id}>
                        <StyledCompanyInfoBlock>
                            <StyledIconBlock>
                                <Icon name={Icons.eagleSvg} />
                            </StyledIconBlock>
                            <StyledItemTitle>
                                {item?.b2b_company_title}
                            </StyledItemTitle>
                        </StyledCompanyInfoBlock>
                        <Checkbox
                            data-testid={`${testIds.companyCard}-${item.b2b_company_id}`}
                            company-name={item?.b2b_company_title}
                            onChange={(e) =>
                                handleCheckCompany(
                                    e.target.checked,
                                    item?.b2b_company_id,
                                )
                            }
                            defaultChecked={
                                item?.access?.user_access ===
                                    UserAccess.DIRECT ||
                                item?.access?.user_access ===
                                    UserAccess.INHERITED
                            }
                        />
                    </StyledCompanyItem>
                ))
            ) : (
                <StyledText>{t("label.noData", "No Data")}</StyledText>
            )}
        </>
    );
};
