import { UiBackendv1CompanyTreeItem } from "lib/api/common/data-contracts";

export interface CompaniesCheckboxListProps {
    items: UiBackendv1CompanyTreeItem[];
    updateItems: React.Dispatch<
        React.SetStateAction<UiBackendv1CompanyTreeItem[]>
    >;
}

export const testIds = { companyCard: "company-card" };
