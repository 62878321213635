import {
    UiBackendv1CompanyTreeItem,
    V1GetCompanyTreeResponse,
} from "lib/api/common/data-contracts";
import { CompanyHierarchy } from "lib/constants/company-hierarchy";
import { UserAccess } from "lib/constants/user-access";
import { UserType } from "lib/constants/user-type";

export const filterCompanies = (
    userType?: UserType,
    companies?: V1GetCompanyTreeResponse,
    userId?: string,
) => {
    const companiesList = companies?.available_b2b_companies || [];

    if (!userType) {
        return [];
    }

    if (userType === UserType.B2B_USER) {
        const isUserHasAccessToRootCompany = !!companiesList.find(
            (company) => company.type === CompanyHierarchy.ROOT,
        );

        const rootCompany = {
            ...companies?.root_b2b_company,
            parent_id: "",
            access: { user_id: userId, user_access: UserAccess.NULL },
        };

        if (!isUserHasAccessToRootCompany) {
            companiesList?.push(rootCompany);
        }

        companiesList?.forEach((company: UiBackendv1CompanyTreeItem) => {
            if (
                !!company?.parent_id &&
                !companiesList?.find(
                    (item) => item?.b2b_company_id === company.parent_id,
                )
            ) {
                company.parent_id = rootCompany.b2b_company_id;
            }
        });

        return companiesList;
    }

    if (userType === UserType.AGGREGATOR_USER) {
        return companiesList?.filter(
            (company) => company?.type === CompanyHierarchy.ROOT,
        );
    }
};

export const getCompanyForGenerateNewAccess = (
    company: UiBackendv1CompanyTreeItem,
) => ({
    id: company.b2b_company_id || "",
    access: company.access?.user_access || "",
});

export const generateNewAccess = (
    companies: { id: string; access: string }[],
    availableCompanies: string[],
) => {
    const newAccessData = { grant: [], revoke: [] } as {
        grant: string[];
        revoke: string[];
    };

    companies.forEach((company) => {
        if (availableCompanies.includes(company.id)) {
            if (company.access === UserAccess.NULL) {
                newAccessData.revoke.push(company.id);
            } else {
                newAccessData.grant.push(company.id);
            }
        }
    });

    return newAccessData;
};
